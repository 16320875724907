.gradient {
    background: -moz-linear-gradient(45deg, rgba(42, 27, 161, 0.7), rgba(29, 210, 177, 0.7) 100%);
    background: -webkit-linear-gradient(45deg, rgba(42, 27, 161, 0.7), rgba(29, 210, 177, 0.7) 100%);
    background: -webkit-gradient(linear, 45deg, from(rgba(42, 27, 161, 0.7)), to(rgba(29, 210, 177, 0.7)));
    background: -o-linear-gradient(45deg, rgba(42, 27, 161, 0.7), rgba(29, 210, 177, 0.7) 100%);
    /* background: linear-gradient(45deg, rgba(42, 27, 161, 0.7), rgba(29, 210, 177, 0.7) 100%); */
    background: linear-gradient(0deg, rgba(247, 233, 248, 1) 24%, rgba(255, 245, 238, 1) 66%);
}

.view {
    background-color: #fff5ee;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    margin-bottom: -7em;
    min-height: 70vh;
}

.footer {
    background-color: #fff5ee;
}

.gallery {
    background-color: #f7e9f8;
    padding-top: 8em;
    padding-bottom: 8em;
}

.icon {
    color: #ea65f7;
    padding-left: 0.35em;
    padding-right: 0.35em;
}

.icon2 {
    color: #333429;
    padding-left: 0.35em;
    padding-right: 0.35em;
}

h2 {
    color: rgba(14, 14, 14, 0.8);
}

.icon:hover {
    color: #e31ef5;
}

.icon-text {
    font-size: 20px !important;
}

.brand {
    color: black;
}

.action-left {
    padding: 50px;
    border-right: 6px solid #f7e9f8;
}

.action-right {
    padding: 50px;
    border-bottom: 6px solid #f7e9f8;
}

strong {
    font-size: 26px;
}

h6 {
    line-height: 1.7;
}

@media only screen and (max-width: 400px) {
    .animated img {
        max-height: 35vh;
        transform: translateX(-50%);
        left: 50%;
        position: absolute;
    }
}
@media only screen and (max-width: 767px) {
    .animated img {
        max-height: 35vh;
        transform: translateX(-50%);
        left: 50%;
    }
    .view {
        min-height: 30vh !important;
        padding-top: 30em;
    }
}

@media (max-width: 767px) and (orientation: landscape) {
    .animated img {
        max-height: 40vh;
        transform: translateX(-50%);
        left: 50%;
        position: relative;
    }
}
@media (max-width: 1000px) and (orientation: landscape) {
    .view {
        min-height: 150vh;
    }
}
