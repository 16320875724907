.image-list {
    columns: 6 200px;
    column-gap: 10px;
    margin: 20px;
}

img {
    width: 100%;
    margin-bottom: 10px;
}
